import React, { useState, useEffect, useRef } from "react";
import { CircularProgress } from "@mui/material";
import { useProgressContext } from "../../ProgressContext";
import axios from "axios";
import "./Inventory.css";

const InventoryView = () => {
  const { progressDetails, setProgressDetails } = useProgressContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [googleProgress, setGoogleProgress] = useState([]);

  useEffect(() => {
    fetchAllProgress();
  }, []);

  const fetchAllProgress = async () => {
    try {
      const response = await axios.get("http://65.0.12.194:3000/inventory");
      const progressData = response.data;
      setProgressDetails(progressData);
      setGoogleProgress(progressData.filter(detail => detail.item !== undefined && detail.item !== null));
    } catch (error) {
      setError("No Internet!");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveSuccess = () => {
    fetchAllProgress();
  };

  if (loading) return <CircularProgress />;
  if (error) return <p style={{ fontWeight: "bold", color: "red" }}>{error}</p>;

  return (
    <div>
      {successMessage && <p style={{ color: "green" }}>{successMessage}</p>}
      
      <div className="header">
        <h1>Averroes Assets</h1>
      </div>
    
      <DataTable progressData={googleProgress} onSaveSuccess={handleSaveSuccess} />
    </div>
  );
};

const DataTable = ({ progressData, onSaveSuccess, hideTotalUserLoss }) => {
  const [editedData, setEditedData] = useState({});
  const [totalMobileDevices, setTotalMobileDevices] = useState(0);
  const [totalComputerSystems, setTotalComputerSystems] = useState(0);
  const [totalAC, setTotalAC] = useState(0);
  const [totalLED_TV, setTotalLED_TV] = useState(0);
  const [totalCCTV_Cameras, setTotalCCTV_Cameras] = useState(0);
  const [totalChairs, setTotalChairs] = useState(0);
  const [totalTable, setTotalTable] = useState(0);
  const [totalMiscellaneous, setTotalMiscellaneous] = useState(0);

  const inputRefs = useRef([]);

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, progressData.length);
    calculateTotals();
  }, [progressData]);

  const calculateTotals = () => {
    let mobileDevicesTotal = 0;
    let computerSystemsTotal = 0;
    let ACsTotal = 0;
    let LED_TVTotal = 0;
    let CCTV_CamerasTotal = 0;
    let chairsTotal = 0;
    let tableTotal = 0;
    let miscellaneousTotal = 0;

    progressData.forEach((detail, index) => {
      if (index <= 16) {
        mobileDevicesTotal += parseInt(detail.qty) || 0;
      } else if (index >= 17 && index <= 24) {
        computerSystemsTotal += parseInt(detail.qty) || 0;
      } else if (index >= 25 && index <= 28) {
        ACsTotal += parseInt(detail.qty) || 0;
      } else if (index >= 29 && index <= 32) {
        LED_TVTotal += parseInt(detail.qty) || 0;
      } else if (index >= 33 && index <= 36) {
        CCTV_CamerasTotal += parseInt(detail.qty) || 0;
      } else if (index >= 37 && index <= 39) {
        chairsTotal += parseInt(detail.qty) || 0;
      } else if (index >= 40 && index <= 44) { 
        tableTotal += parseInt(detail.qty) || 0;
      } else if (index >= 45 && index <= 57) { 
        miscellaneousTotal += parseInt(detail.qty) || 0;
      }
    });
    
    setTotalMobileDevices(mobileDevicesTotal);
    setTotalComputerSystems(computerSystemsTotal);
    setTotalAC(ACsTotal);
    setTotalLED_TV(LED_TVTotal);
    setTotalCCTV_Cameras(CCTV_CamerasTotal);
    setTotalChairs(chairsTotal);
    setTotalTable(tableTotal);
    setTotalMiscellaneous(miscellaneousTotal);
  };

  const handleSave = async (index) => {
    if (progressData[index]) {
      const progressId = progressData[index]._id;
      try {
        await axios.put(`http://65.0.12.194:3000/inventory/${progressId}`, editedData[index]);
        onSaveSuccess();
      } catch (error) {
        console.error("Error updating progress:", error);
      }
    } else {
      console.error("Error: progressData[index] is undefined.");
    }
  };
  const handleInputChange = (e, key, id) => {
    const { value } = e.target;
    setEditedData((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        [key]: value,
      },
    }));
    handleSave(id);
    calculateTotals(); 
  };

  const handleKeyDown = (e, rowIndex, colIndex) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextColIndex = colIndex === 9 ? 1 : colIndex + 1;
      if (nextColIndex < 9) {
        const nextInput = inputRefs.current[rowIndex][nextColIndex];
        if (nextInput) {
          nextInput.focus();
        }
      }
    }
  };

  return (
    <div>
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <button onClick={() => handleSave(0)} style={{ marginBottom: '10px' }}>
        Save
      </button>
    </div>
    <table className="inventory-table">
        <thead>
          <tr>
            <th className="mobile">Mobile device</th>
            <th>{totalMobileDevices}</th>
            <th className="mobile">Computer Systems</th>
            <th>{totalComputerSystems}</th>
            <th className="mobile">AC</th>
            <th>{totalAC}</th>
            <th className="mobile">LED TV</th>
            <th>{totalLED_TV}</th>
          </tr>
        </thead>
        <tbody>
        <th className="mobile">CCTV Cameras</th>
            <th>{totalCCTV_Cameras}</th>
            <th className="mobile">Chairs</th>
            <th>{totalChairs}</th>
            <th className="mobile">Table</th>
            <th>{totalTable}</th>
            <th className="mobile">Miscellaneous</th>
            <th>{totalMiscellaneous}</th>       
        </tbody>
      </table>
    <table className="styled-tab">
      <thead>
        <tr style={{ backgroundColor: "#bdddff" }}>
          <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>Mobile Devices</th>
        </tr>
        <tr style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
          <th className= "sno">S.No</th>
          <th className= "item">Item</th>
          <th className= "item">Brand</th>
          <th className= "item">Model No</th>
          <th className= "item">Device Version</th>
          <th className= "item">IMEI</th>
          {!hideTotalUserLoss && <th className= "item">Issued To</th>}
          <th className= "sno">Qty</th>
          <th className= "item">Comments</th>
          <th className= "item">Price</th>
        </tr>
      </thead>
      <tbody>
        {progressData.map((detail, rowIndex) => (
  <React.Fragment key={detail._id}>
    {rowIndex === 17 && (
      <>
        <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#bdddff" }}>
          <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>Computer Systems</th>
        </tr>

        <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
          <th>S.No</th>
          <th>Item</th>
          <th>Brand</th>
          <th>Model No</th>
          <th>Accessories</th>
          <th>Issued To</th>
          {!hideTotalUserLoss && <th>Location</th>}
          <th>Qty</th>
          <th>Comments</th>
          <th>Price</th>
        </tr>
      </>
    )}
    {rowIndex === 25 && (
            <>
            <tr key={`headerRow${rowIndex}`} style={{ color: "#5d7b9c", backgroundColor: "transparent" }}>
            <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>AC</th>
                  </tr>
      <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
        <th>S.No</th>
        <th>Item</th>
        <th>Brand</th>
        <th>Model No</th>
        <th>Accessories</th>
        <th>Ton Capicaity</th>
        {!hideTotalUserLoss && <th>Location</th>}
        <th>Qty</th>
        <th>Comments</th>
        <th>Price</th>

      </tr>
      </>
    )}
     {rowIndex === 29 && (
        <>
        <tr key={`headerRow${rowIndex}`} style={{ color: "#5d7b9c", backgroundColor: "transparent" }}>
        <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>LED TV</th>
              </tr>
      <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
        <th>S.No</th>
        <th>Item</th>
        <th>Brand</th>
        <th>Model No</th>
        <th>Version</th>
        <th>Accessories</th>
        {!hideTotalUserLoss && <th>Location</th>}
        <th>Qty</th>
        <th>Comments</th>
        <th>Price</th>

      </tr>
      </>
    )}
      {rowIndex === 33 && (
         <>
         <tr key={`headerRow${rowIndex}`} style={{ color: "#5d7b9c", backgroundColor: "transparent" }}>
         <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}> CCTV Cameras</th>
               </tr>
      <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
        <th>S.No</th>
        <th>Item</th>
        <th>Brand</th>
        <th>Model No</th>
        <th>Version</th>
        <th>Accessories</th>
        {!hideTotalUserLoss && <th>Location</th>}
        <th>Qty</th>
        <th>Comments</th>
        <th>Price</th>

      </tr>
      </>
    )}
     {rowIndex === 37 && (
       <>
       <tr key={`headerRow${rowIndex}`} style={{ color: "#5d7b9c", backgroundColor: "transparent" }}>
       <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>Chairs</th>
             </tr>
      <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
        <th>S.No</th>
        <th>Item</th>
        <th>Brand</th>
        <th>Model No</th>
        <th>Version</th>
        <th>Accessories</th>
        {!hideTotalUserLoss && <th>Location</th>}
        <th>Qty</th>
        <th>Comments</th>
        <th>Price</th>

      </tr>
      </>
    )}
     {rowIndex === 40 && (
      <>
      <tr key={`headerRow${rowIndex}`} style={{ color: "#5d7b9c", backgroundColor: "transparent" }}>
      <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>Table</th>
            </tr>
      <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
        <th>S.No</th>
        <th>Item</th>
        <th>Brand</th>
        <th>Model No</th>
        <th>Version</th>
        <th>Accessories</th>
        {!hideTotalUserLoss && <th>Location</th>}
        <th>Qty</th>
        <th>Comments</th>
        <th>Price</th>

      </tr>
      </>
    )}
     {rowIndex === 45 && (
       <>
       <tr key={`headerRow${rowIndex}`} style={{ color: "#5d7b9c", backgroundColor: "transparent" }}>
       <th colSpan="10" style={{ textAlign: "center", color: "black", backgroundColor: "#bdddff" }}>Miscellaneous</th>
             </tr>
      <tr key={`headerRow${rowIndex}`} style={{ backgroundColor: "#5d7b9c", color: "#fff" }}>
        <th>S.No</th>
        <th>Item</th>
        <th>Brand</th>
        <th>Model No</th>
        <th>Version</th>
        <th>Accessories</th>
        {!hideTotalUserLoss && <th>Location</th>}
        <th>Qty</th>
        <th>Comments</th>
        <th>Price</th>

      </tr>
      </>
    )}
            <tr key={`dataRow${detail._id}`}>

            <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.sNo ||  detail.sNo || ""}
                  onChange={(e) => handleInputChange(e, "sNo", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 1)}
                />
              </td>


              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.item || detail.item || ""}
                  onChange={(e) => handleInputChange(e, "item", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 2)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.brand  || detail.brand  || ""}
                  onChange={(e) => handleInputChange(e, "brand", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 3)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.modelNo  || detail.modelNo  || ""}
                  onChange={(e) => handleInputChange(e, "modelNo", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 4)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.deviceVersion || detail.deviceVersion || ""}
                  onChange={(e) => handleInputChange(e, "deviceVersion", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 5)}
                />
              </td>
              <td>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
  <input
    style={{ marginBottom: '1px' }}
    value={editedData[rowIndex]?.iMEI || detail.iMEI || ""}
    onChange={(e) => handleInputChange(e, "iMEI", rowIndex)}
    onBlur={() => handleSave(rowIndex)}
    onKeyDown={(e) => handleKeyDown(e, rowIndex, 6)}
  />
  {rowIndex >= 0 && rowIndex <= 16 && (
    <input
      value={editedData[rowIndex]?.secondIMEI || detail.secondIMEI || ""}
      onChange={(e) => handleInputChange(e, "secondIMEI", rowIndex)}
      onBlur={() => handleSave(rowIndex)}
      // style={{ border: 'none', marginLeft: '-1px' }} // Adjust margin-left to remove space
    />
  )}
</div>
</td>

              {!hideTotalUserLoss && (
                <td>
                  <input
                    ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                    value={editedData[rowIndex]?.issuedTo || detail.issuedTo  || ""}
                    onChange={(e) => handleInputChange(e, "issuedTo", rowIndex)}
                    onBlur={() => handleSave(rowIndex)}
                    onKeyDown={(e) => handleKeyDown(e, rowIndex, 7)}
                  />
                </td>
              )}
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.qty  || detail.qty  || ""}
                  onChange={(e) => handleInputChange(e, "qty", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 8)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.comments || detail.comments || ""}
                  onChange={(e) => handleInputChange(e, "comments", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 9)}
                />
              </td>
              <td>
                <input
                  ref={(el) => (inputRefs.current[rowIndex] = [...(inputRefs.current[rowIndex] || []), el])}
                  value={editedData[rowIndex]?.price || detail.price || ""}
                  onChange={(e) => handleInputChange(e, "price", rowIndex)}
                  onBlur={() => handleSave(rowIndex)}
                  onKeyDown={(e) => handleKeyDown(e, rowIndex, 10)}
                />
              </td>
            </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryView;
